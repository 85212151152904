.banner-text-container {
    margin-top: -20rem; /* Increase margin for larger screens */
  }
  
  .banner-thumb {
    margin-top: -6rem; /* Adjust margin for the image */
  }
  
  .banner-text-content {
    font-size: 30px;
  }
  
  @media (max-width: 1200px) {
    .banner-text-container {
      margin-top: -15rem; /* Increase margin for this screen size */
    }
  
    .banner-thumb {
      margin-top: -4rem;
    }
  
    .banner-text-content {
      font-size: 28px;
    }
  }
  
  @media (max-width: 992px) {
    .banner-text-container {
      margin-top: -12rem; /* Increase margin for this screen size */
    }
  
    .banner-thumb {
      margin-top: -3rem;
    }
  
    .banner-text-content {
      font-size: 26px;
    }
  }
  
  @media (max-width: 768px) {
    .banner-text-container {
      margin-top: -10rem; /* Increase margin for this screen size */
    }
  
    .banner-thumb {
      margin-top: -1rem;
    }
  
    .banner-text-content {
      font-size: 24px;
    }
  }
  
  @media (max-width: 576px) {
    .banner-text-container {
      margin-top: -8rem; /* Increase margin for this screen size */
    }
  
    .banner-thumb {
      margin-top: -1rem;
    }
  
    .banner-text-content {
      font-size: 22px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-text-container {
      margin-top: -6rem; /* Increase margin for this screen size */
    }
  
    .banner-text-content {
      font-size: 20px;
    }
  }
  