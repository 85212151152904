.card-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .card-style:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
.card-col {
    margin-bottom: 1rem;
  } 
 
 /* Large screens */
 @media (min-width: 992px) { /* lg breakpoint */
    .card-col-lg {
      flex: 0 0 20%; /* 100% / 5 cards = 20% width */
      max-width: 20%;
    }
  }
  
  /* Medium screens */
  @media (min-width: 768px) and (max-width: 991px) { /* md breakpoint */
    .card-col-md {
      flex: 0 0 33.33%; /* 100% / 3 cards = 33.33% width */
      max-width: 33.33%;
    }
  }
  
  /* Small screens */
  @media (min-width: 576px) and (max-width: 767px) { /* sm breakpoint */
    .card-col-sm {
      flex: 0 0 33.33%; /* 100% / 2 cards = 50% width */
      max-width: 33.33%;
    }
  }
  
  /* Extra-small screens */
  @media (max-width: 575px) { /* xs breakpoint */
    .card-col-xs {
      flex: 0 0 50%; /* 100% / 2 cards = 50% width */
      max-width: 50%;
    }
  }